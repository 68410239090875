export const environment = {
  production: 'false',
  authority: 'https://avesdo-sso-demo.azurewebsites.net', // SSO Server URL
  analyticsUrl: 'https://avesdo-dev-analytics.azurewebsites.net', // Analytics API URL
  coreBaseUrl: 'https://demo.avesdo.com', // Core Avesdo URL
  imgServerUrl: 'https://yongledemocanada.blob.core.windows.net',
  mktplaceBaseUrl: 'https://realtor-demo.offplan.homes',
  mktplaceApiUrl: 'https://avesdo-demo-offers-api.azurewebsites.net/api', // MP API URL
  coreMarketplaceApiUrl: 'https://avesdo-demo-core-mp-api.azurewebsites.net',
  crmBaseUrl: 'https://crm-demo.avesdo.net',
  crmApiUrl: 'https://avesdo-demo-apigateway.azurewebsites.net',
  environmentType: 'Demo'
};
