export const environment = {
  production: 'false',
  authority: 'https://avesdo-sso-demo.azurewebsites.net', // SSO Server URL
  analyticsUrl: 'https://avesdo-dev-analytics.azurewebsites.net', // Analytics API URL
  coreBaseUrl: 'https://demo.avesdo.com', // Core Avesdo URL,
  coreMarketplaceApiUrl: 'https://avesdo-demo-core-mp-api.azurewebsites.net',
  imgServerUrl: 'https://yongledemocanada.blob.core.windows.net',
  mktplaceBaseUrl: 'https://realtor-demo.offplan.homes',
  mktplaceDeveloperBaseUrl: 'https://developer-demo.offplan.homes',
  mktplaceApiUrl: 'https://avesdo-demo-offers-api.azurewebsites.net/api', // MP API URL
  crmBaseUrl: 'https://crm-demo.avesdo.net',
  crmApiUrl: 'https://avesdo-demo-apigateway.azurewebsites.net',
  crmRerouteUrl : 'https://avesdo-dev-reroute.azurewebsites.net',
  buyersPortalApiUrl: '',
  buyersPortalCookieDomain: '',
  marketplaceCookieDomain: 'realtor-demo.offplan.homes',
  buyersPortalBaseUrl: '',
  utilityServiceUrl: 'https://avesdo-demo-utilityservice.azurewebsites.net/api',

  stripeApiKey: 'pk_test_oyA1ZUgjkc1yJGbooO8kbf2P00XzpBfbCG',
  //SSO variables
  googleClientId: '',
  microsoftClientId: '9695fcee-afbc-4940-bb20-f6987ea4a443',
  microsoftAuthority: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  redirectUrl: 'https://demo.avesdo.com/Account/EmailSettings',
  googleAuthority: '',
  googleApiScopeGmail: '',
  facebookAppId: '',
  customMsalClientId: '',
  msalSignUpAuthority: '',
  msalResetPasswordAuthority: '',

  //Google Maps
  googleMapApiKey: 'AIzaSyCGPhGXdo5fmhjM_kQDjsthS1vDLJyKAR0',
  hubSpotSrc: '',
  googleAnalyticsId: '',

  //Signal R
  signalRUrl: 'https://avesdo-demo-apigateway.azurewebsites.net/signalrhub/gridUpdate',

  environmentType: 'Demo'
};
