import { HttpEventType } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AllowedFileTypes } from '../../constants/file-upload-constants';
import { FileData } from '../../models/bulk-file-upload/file-data';
import { FileSummaryGridComponentData, FileUploadComponentData } from '../../models/bulk-file-upload/file-upload-component-data';
import { BulkFileUploadService } from '../../services/avBulkFileUpload/bulk-file-upload.service';
import { FileValidationService } from '../../services/avBulkFileUpload/file-validation.service';

@Component({
  selector: 'lib-av-bulk-image-upload',
  templateUrl: './av-bulk-file-upload.component.html',
  styleUrls: ['./av-bulk-file-upload.component.scss'],
})
export class AvBulkFileUploadComponent implements OnInit, AfterViewInit {
  fileUploadComponentData: FileUploadComponentData;
  allowedFiles: string[] = [];
  hasTriggereredFileUpload: boolean = false;
  selectedFilesList: File[] = [];
  toUploadFilesList: FileData[] = [];
  mainHeaderTitle: string = '';
  uploadUrl: string = '';
  fileSummaryGridComponentData: FileSummaryGridComponentData;

  @ViewChild('fileUploadElement') fileUploadElement: ElementRef;

  constructor(
    private bulkFileUploadService: BulkFileUploadService,
    private dialogRef: MatDialogRef<AvBulkFileUploadComponent>,
    @Inject(MAT_DIALOG_DATA) private data: FileUploadComponentData,
    private fileValidationService: FileValidationService
  ) {
    this.fileUploadComponentData = this.data;
  }

  ngOnInit(): void {
    this.initiateComponentData();
  }


  ngAfterViewInit() {
    this.enableBrowseFromDirectory();
  }

  onFileSelected(files: File[]) {
    this.selectedFilesList  = files;
    if (this.data.isFromDirectory) this.sanitizeFilesList();
  }

  onDropFiles(files: any): void {
    if (files) {
      this.selectedFilesList = files;
      if (this.data.isFromDirectory) this.sanitizeFilesList();
    }
  }

  // This abstract method needs for the Drag Zone Directive
  onHover(event: any): void {}

  onCancelTheFlow(event: boolean): void {
    if (event) this.dialogRef.close();
  }

  onFinishTheFlow(event: boolean): void {
    if (event) {
      this.data.onFileUploadSuccess(this.toUploadFilesList);
      this.dialogRef.close();
    }
  }

  invokeFilesUpload(toUploadFilesList: FileData[]): void {
    this.toUploadFilesList = toUploadFilesList;
    this.toUploadFilesList.forEach((x) => {
      const fileUploadProgress = {
        progress: 0,
        hasError: false,
        errorMessage: '',
        isInProgress: true,
        uploadSuccess: false,
      };
      x.uploadingProgress = fileUploadProgress;
    });
    this.constructAndUploadFiles();
  }



  private enableBrowseFromDirectory(): void {
    if (this.fileUploadComponentData.isFromDirectory)
      this.fileUploadElement.nativeElement.setAttribute(
        'webkitdirectory',
        true
      );
  }

  private initiateComponentData(): void {
    this.allowedFiles = (this.fileUploadComponentData.allowedFiles) 
    ? this.fileUploadComponentData.allowedFiles 
    : AllowedFileTypes;
    this.mainHeaderTitle = this.fileUploadComponentData.mainHeaderTitle;
    this.uploadUrl = this.fileUploadComponentData.uploadRequestParameters.uploadUrl;
    this.fileSummaryGridComponentData = this.fileUploadComponentData.fileSummaryGridData;
  }

  private constructAndUploadFiles(): void {
    let toUploadFileDataList: FormData[] = [];
    this.toUploadFilesList.forEach((file: FileData) => {
      let formData = new FormData();
      this.data.uploadRequestParameters.defaultFields.forEach(
        (field: { key: string; value: string }) => {
          formData.append(field.key, field.value);
        }
      );
      this.data.uploadRequestParameters.inputFields.forEach((field) => {
        formData.append(field.key, file[field.summaryGridField]);
      });
      formData.append(
        this.data.uploadRequestParameters.fileAsName
          ? this.data.uploadRequestParameters.fileAsName
          : 'httpFile',
        file.rowFile
      );
      toUploadFileDataList.push(formData);
    });
    this.uploadFiles(toUploadFileDataList);
  }


  private uploadFiles(filesToUpload: FormData[]): void {
    this.hasTriggereredFileUpload = true;
    const requestsList = this.consturctChainRequests(filesToUpload);
    this.invokeFileUploadProcess(requestsList);
  }

  private consturctChainRequests(filesToUpload: FormData[]): any {
    return filesToUpload.map((formData: FormData, index) => {
      return this.bulkFileUploadService
        .uploadBulkFiles(this.uploadUrl, formData)
        .pipe(
          tap((event) => {
            if (event.type == HttpEventType.UploadProgress) {
              this.toUploadFilesList[index].uploadingProgress.progress =
                Math.round(100 * (event.loaded / event.total));
            }
            this.onFileUploadSuccess(event, index);
          }),
          catchError((error) => {
            return of({ isError: true, index, error });
          })
        );
    });
  }

  private invokeFileUploadProcess(requestsList: any[]): void {
    forkJoin(requestsList).subscribe((response: any[]) => {
      response.forEach((item) => {
        if (item.isError) {
          this.toUploadFilesList[item.index].uploadingProgress.hasError = true;
          this.toUploadFilesList[item.index].uploadingProgress.errorMessage =
            item.error.statusText;
        }
      });
    });
  }

  private onFileUploadSuccess(event: any, index: number): void {
    if (event.type !== HttpEventType.Response) return;
    this.toUploadFilesList[index].uploadingProgress.isInProgress = false;
    if (event.body.Success) {
      this.toUploadFilesList[index].uploadingProgress.uploadSuccess = true;
      return;
    }
    this.toUploadFilesList[index].uploadingProgress.hasError = true;
    this.toUploadFilesList[index].uploadingProgress.errorMessage =
      event.body.UserMessage;
  }

  private sanitizeFilesList(): void {
    this.filterValidFileType();
    this.filterValidFileSize();
  }

  private filterValidFileType(): void {
    this.selectedFilesList = Object.values(this.selectedFilesList).filter((x) =>
      this.fileValidationService.isValidFileType(x, this.allowedFiles)
    );
  }

  private filterValidFileSize(): void {
    this.selectedFilesList = Object.values(this.selectedFilesList).filter((x) =>
      this.fileValidationService.isValidFileSize(x)
    );
  }
}
